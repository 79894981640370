import React from "react";
import MainTitle from "components/molecules/MainTitle/MainTitle";
import GeneralHelmet from "components/molecules/GeneralHelmet/GeneralHelmet";
import RichText from "components/atoms/RichText/RichText";

const Legal = ({ data }) => {
  const { meta, title, subtitle, content } = data;
  return (
    <>
      <GeneralHelmet meta={meta} />
      <div className="page-content">
        <div className="about">
          <MainTitle title={title} subtitle={subtitle} />
          <div className="rich-text slice">
            <RichText content={content} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Legal;
